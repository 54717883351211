/* Common Imports */

import React from "react";
import { withRouter } from "next/router";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */

import { Link, ListItem, ListItemText } from "@material-ui/core";

/* Data Imports */

// import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  sideNavButton: {
    color: "#666666",
    borderRight: "solid 3px #FFFFFF",
    backgroundColor: "#ffffff",
    paddingLeft: "2rem",
  },
  sideNavButtonActive: {
    color: "#08A945",
    borderRightStyle: "solid",
    borderRightWidth: "3px",
    borderImage: "-webkit-linear-gradient(180deg, #08A945 0%, #0FCA56 100%) 1",
    paddingLeft: "2rem",
  },
  sideNavButtonText: {
    fontWeight: "400",
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
  },
}));

const SideNavDropdownItem = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {/* Dropdown List Item */}

      <Link
        href={props.href}
        onClick={props.onClick}
        rel="noopener"
        aria-label={props.text}
        title={props.text}
        className={classes.link}
      >
        <ListItem
          button
          className={
            props.router.pathname == props.href
              ? classes.sideNavButtonActive
              : classes.sideNavButton
          }
        >
          <ListItemText
            primary={props.text}
            primaryTypographyProps={{
              className: classes.sideNavButtonText,
            }}
          />
        </ListItem>
      </Link>
    </React.Fragment>
  );
};

export default withRouter(SideNavDropdownItem);
