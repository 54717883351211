/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */
import { Link } from "@material-ui/core";

/* Data Imports */
import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  rootRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "20% ",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0rem 1rem 0rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      padding: "2rem 0rem 1rem 0rem",
    },
  },
  iconLabelRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0.5rem",
  },
}));

const FooterSocialIcons = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.rootRow}>
        {/* Twitter */}

        <Link
          href="https://twitter.com/sentientdevelop"
          rel="noopener"
          title="Twitter"
          target="_blank"
          className={classes.iconLabelRow}
        >
          <img
            src="/images/icons/footer/twitter.svg"
            alt={AppConfig.imageAltText}
            width={18}
            height={18}
            loading="lazy"
          />
        </Link>

        {/* Instagram */}

        <Link
          href="https://www.instagram.com/sentient.developers/"
          rel="noopener"
          title="Instagram"
          target="_blank"
          className={classes.iconLabelRow}
        >
          <img
            src="/images/icons/footer/instagram.svg"
            alt={AppConfig.imageAltText}
            width={18}
            loading="lazy"
            height={18}
          />
        </Link>

        {/* Facebook */}

        <Link
          href="https://www.facebook.com/sentient.developers"
          rel="noopener"
          title="Facebook"
          target="_blank"
          className={classes.iconLabelRow}
        >
          <img
            src="/images/icons/footer/facebook.svg"
            alt={AppConfig.imageAltText}
            width={18}
            loading="lazy"
            height={18}
          />
        </Link>

        {/* LinkedIn */}

        <Link
          href="https://www.linkedin.com/company/sentientdev"
          rel="noopener"
          title="LinkedIn"
          target="_blank"
          className={classes.iconLabelRow}
        >
          <img
            src="/images/icons/footer/linkedin.svg"
            alt={AppConfig.imageAltText}
            width={18}
            loading="lazy"
            height={18}
          />
        </Link>
      </div>
    </React.Fragment>
  );
};

export default FooterSocialIcons;
