/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Link, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      width: "25%",
    },
  },
  heading: {
    margin: "0rem 0rem 2rem 0rem",
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "1.8rem",
    color: "#0FCA56",
    // background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      margin: "1.5rem 0rem 1rem 0rem",
    },
  },
  subHeading: {
    margin: "0rem 0rem 0.5rem 0rem",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: "#666666",
  },
}));

const FooterCompanyLinks = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography className={classes.heading}>Company</Typography>
        <Link
          href="/terms"
          rel="noopener"
          title="Terms & Conditions"
          target="_blank"
          className={classes.subHeading}
        >
          Terms & Conditions
        </Link>
        <Link
          href="/privacy"
          rel="noopener"
          title="Terms & Conditions"
          target="_blank"
          className={classes.subHeading}
        >
          Privacy Policy
        </Link>
        <Link
          href="/refund-cancellation-policy"
          rel="noopener"
          title="Refund / Cancellation Policy"
          target="_blank"
          className={classes.subHeading}
        >
          Refund / Cancellation Policy
        </Link>
      </div>
    </React.Fragment>
  );
};

export default FooterCompanyLinks;
