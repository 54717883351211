/* Common Imports */
import React from "react";
import { withRouter } from "next/router";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import { Button, Menu, MenuItem } from "@material-ui/core";
import NavDropdownItem from "./NavDropdownMenuItem";

/* Data Imports */
import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "fit-content",
    display: "inline-block",
  },
  navButtonActive: {
    borderBottomStyle: "solid",
    borderWidth: "0px 0px 3px 0px",
    borderImage: "-webkit-linear-gradient(180deg, #74378E 0%, #502761 100%) 1",
    padding: "1.5rem 1rem 1.3rem 0rem",
    borderRadius: 0,
    color: "-webkit-linear-gradient(180deg, #74378E 0%, #502761 100%)",
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1rem",
    letterSpacing: "0.047rem",
    textTransform: "capitalize",
    letterSpacing: "0.047rem",
  },
  navButton: {
    color: "#666666",
    borderBottom: "solid 3px #FFFFFF",
    padding: "1.3rem 1rem 1rem 1rem",
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1rem",
    letterSpacing: "0.047rem",
    textTransform: "capitalize",
  },
  menu: {
    borderRadius: "5px",
    // width: "40rem",
    marginTop: "0.1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    "& .MuiList-padding": {
      padding: 0,
    },
  },
  menuItem: {
    width: "10rem",
    padding: "0rem",
    "&:hover": {
      background: "#f1f1f1",
    },
  },
  // hideThis: {
  //   display: "none",
  // },
  btnText: {
    marginLeft: "1rem",
  },
  btnTextActive: {
    // color: "#cc0000",
    // background: "-webkit-linear-gradient(0deg, #F74134, #EB1C61)",
    // "-webkit-background-clip": "text",
    // "-webkit-text-fillColor": "transparent",
    marginLeft: "0.5rem",
  },
}));

const DispenseIcon = (iconString) => {
  switch (iconString) {
    case "navbar/company/active":
      return "/images/icons/nav/company-red.svg";
    case "navbar/arrowdown":
      return "/images/icons/nav/arrowdown.svg";
    case "navbar/arrowdown/active":
      return "/images/icons/nav/arrowdown-red.svg";
    default:
      return "/images/icons/nav/company-red.svg";
  }
};

const NavDropdownMenuUser = (props) => {
  const classes = useStyles();
  const iconColor = props.router.pathname.startsWith(props.href)
    ? "active"
    : "inactive";

  // const navIconClassActive = props.router.pathname.startsWith(props.href)
  //   ? ""
  //   : classes.hideThis;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Button
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={
            props.router.pathname.startsWith(props.href)
              ? classes.navButtonActive
              : classes.navButton
          }
        >
          {/* Button Icon */}
          {/* <img

          alt={AppConfig.imageAltText}

          loading="lazy"
          referrerPolicy="no-referrer"
          height={26}
          width={26}
          className={navIconClassActive}
          style={{ marginRight: "0.25rem", marginLeft: "0.7rem" }}
          src={DispenseIcon("navbar/company/active")}
        /> */}

          {/* Button Text */}
          <span
            className={
              props.router.pathname.startsWith(props.href)
                ? classes.btnText
                : classes.btnText
            }
          >
            {props.text}
          </span>

          {/* Arrow Down Icon */}
          <img
            alt={AppConfig.imageAltText}
            loading="eager"
            referrerPolicy="no-referrer"
            height={13}
            width={13}
            style={{ marginRight: "0rem", marginLeft: "0.7rem" }}
            src={DispenseIcon(
              iconColor == "inactive"
                ? "navbar/arrowdown"
                : "navbar/arrowdown/active"
            )}
          />
        </Button>
        <Menu
          disableScrollLock
          id="company-menu"
          className={classes.menu}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem className={classes.menuItem}>
            <NavDropdownItem href={props.href} text="My Dashboard" />
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <NavDropdownItem
              href="#"
              text="Sign out"
              onClick={props.onClickSignOut}
            />
          </MenuItem>
        </Menu>
      </div>
    </React.Fragment>
  );
};

export default withRouter(NavDropdownMenuUser);
