/* React Imports */

import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "next/router";
// import Script from "next/script";

/* Component Imports */

import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Fabs from "./Fabs";
import BeegruContainer from "./BeegruContainer";
import ComingSoonPlaceholder from "./subcomponents/ComingSoonPlaceholder";

/* Data Imports */

import AppConfig from "../../AppConfig";

const Layout = (props) => {
  return (
    <React.Fragment>
      <div id="rootDiv">
        <Header
          keywords={
            props.keywords
              ? props.keywords
              : "sentient,heavie,beegru,real,estate,advisory,pwa,next,property,bangalore,bengaluru,home,house,apartment,land,plot,rent,buy,sell,agriculture,farm,construction,architect,architecture,interior,design,material,ui,township,react"
          }
          description={
            props.description
              ? props.description
              : "Sentient was born to provide sustainable solutions to the biggest problems and challenges of real estate and construction and strives to design and develop self-sustaining societal ecosystems on the principles of nature, which will show the way for the future of humanity."
          }
          title={props.title ? props.title : "Sentient"}
          canonicalUrl={
            props.canonicalUrl
              ? props.canonicalUrl
              : AppConfig.urlPrefix + props.router.asPath.substring(1)
          }
        />
        {props.hideNavbar ? undefined : (
          <Navbar hideNavLinks={props.hideNavLinks} user={props.user} />
        )}
        <Helmet>
          <html lang="en-GB" />
        </Helmet>
        {props.backgroundPlaceholder ? <ComingSoonPlaceholder /> : undefined}
        {props.noContainer ? (
          props.children
        ) : (
          <BeegruContainer>{props.children}</BeegruContainer>
        )}
        {props.hideFabs ? undefined : <Fabs />}
        {props.hideFooter ? undefined : <Footer id="footer" />}
        {/* <Script
          type="application/ld+json"
          dangerouslySetInnerHTML={
            props.jsonLDSchema
              ? props.jsonLDSchema
              : {
                  __html: JSON.stringify({
                    "@context": "https://schema.org/",
                    "@type": "RealEstateListing",
                    name: "Party Coffee Cake",
                    author: {
                      "@type": "Person",
                      name: "Mary Stone",
                    },
                    datePublished: "2018-03-10",
                    description:
                      "This coffee cake is awesome and perfect for parties.",
                    prepTime: "PT20M",
                  }),
                }
          }
        /> */}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Layout);
