/* Common Imports */

import React from "react";
import { withRouter } from "next/router";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */

import { List, ListItem, ListItemText, Collapse } from "@material-ui/core";
import SideNavDropdownItem from "./SideNavDropdownItem";

/* Data Imports */

import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  sideNavButton: {
    color: "#666666",
    borderRight: "solid 3px #FFFFFF",
    background: "#ffffff",
  },
  sideNavButtonActive: {
    color: "#08A945",
    borderRightStyle: "solid",
    borderRightWidth: "3px",
    borderImage: "-webkit-linear-gradient(180deg, #08A945 0%, #0FCA56 100%) 1",
  },
  sideNavButtonText: {
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
}));

const DispenseIcon = (iconString) => {
  switch (iconString) {
    case "navbar/company":
      return "/images/icons/nav/company.svg";
    case "navbar/company/active":
      return "/images/icons/nav/company-red.svg";
    case "navbar/arrowdown":
      return "/images/icons/nav/arrowdown.svg";
    case "navbar/arrowdown/active":
      return "/images/icons/nav/arrowdown-green.svg";
    case "navbar/arrowup":
      return "/images/icons/nav/arrowup.svg";
    case "navbar/arrowup/active":
      return "/images/icons/nav/arrowup-green.svg";
    default:
      return "/images/icons/nav/home.svg";
  }
};

const SideNavDropdownCompany = (props) => {
  const classes = useStyles();
  const iconColor =
    props.router.pathname === "/eden" || props.router.pathname === "/halcyon"
      ? "active"
      : "inactive";

  const [open, setOpen] = React.useState(props.open ? props.open : false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      {/* SideNav Button */}

      <ListItem
        button
        onClick={handleClick}
        className={
          props.router.pathname === "/eden" ||
          props.router.pathname === "/halcyon"
            ? classes.sideNavButtonActive
            : classes.sideNavButton
        }
      >
        {/* Button Text */}

        <ListItemText
          primary={props.text}
          primaryTypographyProps={{
            className: classes.sideNavButtonText,
          }}
        />

        {/* Arrow up/down icons  */}

        <img
          alt={AppConfig.imageAltText}
          loading="lazy"
          referrerPolicy="no-referrer"
          height={14}
          width={14}
          style={{ marginRight: "0.25rem" }}
          src={DispenseIcon(
            open
              ? iconColor == "inactive"
                ? "navbar/arrowup"
                : "navbar/arrowup/active"
              : iconColor == "inactive"
              ? "navbar/arrowdown"
              : "navbar/arrowdown/active"
          )}
        />
      </ListItem>

      {/* Dropdown List */}

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <SideNavDropdownItem href="/eden" text="Heavie Eden" />
          <SideNavDropdownItem href="/halcyon" text="Heavie Halcyon" />
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default withRouter(SideNavDropdownCompany);
