/* Common Imports */

import React from "react";
import { withRouter } from "next/router";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */

import { Button, Link } from "@material-ui/core";

/* Data Imports */

// import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  link: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "&:hover": {
      textDecoration: "none",
    },
  },
  navButtonActive: {
    borderBottomStyle: "solid",
    borderWidth: "0px 0px 3px 0px",
    borderImage: "-webkit-linear-gradient(180deg, #08A945 0%, #0FCA56 100%) 1",
    padding: "1.5rem 1rem 1.3rem 0rem",
    borderRadius: 0,
    color: "#08A945",
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1rem",
    letterSpacing: "0.047rem",
    textTransform: "capitalize",
    letterSpacing: "0.047rem",
  },
  navButton: {
    color: "#666666",
    borderBottom: "solid 3px #FFFFFF",
    padding: "1.5rem 1rem 1.3rem 1rem",
    borderRadius: 0,
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1rem",
    letterSpacing: "0.047rem",
    textTransform: "capitalize",
  },
  marginLeft: {
    marginLeft: "0.5rem",
  },
}));

const NavButton = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Link
        href={props.href}
        rel="noopener"
        aria-label={props.text}
        title={props.text}
        className={classes.link}
      >
        <Button
          aria-controls={props.ariaControls}
          aria-haspopup={props.ariaHaspopup}
          onClick={props.onClick}
          className={
            props.router.pathname == props.href
              ? classes.navButtonActive
              : classes.navButton
          }
        >
          <span
            className={
              props.router.pathname == props.href
                ? classes.marginLeft
                : classes.marginLeft
            }
          >
            {props.text}
          </span>
        </Button>
      </Link>
    </React.Fragment>
  );
};

export default withRouter(NavButton);
