/* Common Imports */
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import Fab from "@material-ui/core/Fab";

/* Data Imports */
import AppConfig from "../../AppConfig";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 5,
  },
  fabTop: {
    background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    color: "#FFFFFF",
  },
  fabCall: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    color: "#FFFFFF",
  },
  fabWhatsapp: {
    background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    color: "#FFFFFF",
  },
}));

const Fabs = (props) => {
  const classes = useStyles();

  const [visible, setVisible] = React.useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  const isBrowser = typeof window !== `undefined`;
  if (isBrowser) {
    window.addEventListener("scroll", toggleVisible);
  }

  const handleClickTop = () => {
    document.body.scrollTop = 0; /* For Safari */
    document.documentElement.scrollTop = 0; /* For Chrome, Firefox, IE and Opera */
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        {/* Go to top */}
        <div>
          <Fab
            className={classes.fabTop}
            size="small"
            style={{ display: visible ? "inline" : "none" }}
            aria-label="Go to top"
            onClick={handleClickTop}
          >
            <img
              alt={AppConfig.imageAltText}
              loading="lazy"
              referrerPolicy="no-referrer"
              height={28}
              width={28}
              style={{ padding: "0.25rem", marginTop: "0.3rem" }}
              src="/images/icons/misc/arrow_up-white.svg"
            />
          </Fab>
        </div>

        {/* Call */}
        <div>
          <Fab
            className={classes.fabCall}
            size="small"
            color="primary"
            aria-label="Call"
            href="tel:+919513689999"
          >
            <img
              alt={AppConfig.imageAltText}
              loading="lazy"
              referrerPolicy="no-referrer"
              height={28}
              width={28}
              style={{ padding: "0.25rem" }}
              src="/images/icons/social/phone-white.svg"
            />
          </Fab>
        </div>

        {/* Whatsapp */}
        <div>
          <Fab
            className={classes.fabWhatsapp}
            size="small"
            color="primary"
            aria-label="Whatsapp"
            href="//wa.me/+919513689999"
          >
            <img
              alt={AppConfig.imageAltText}
              loading="lazy"
              referrerPolicy="no-referrer"
              height={28}
              width={28}
              style={{ padding: "0.25rem" }}
              src="/images/icons/social/whatsapp-white.svg"
            />
          </Fab>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Fabs;
