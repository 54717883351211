/* Common Imports */
import React from "react";
import { withRouter } from "next/router";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

/* Component Imports */
import {
  AppBar,
  Toolbar,
  IconButton,
  Link,
  useScrollTrigger,
} from "@material-ui/core";
import NavDropdownMenuUser from "./navbar-components/NavDropdownMenuUser";
import NavDropdownMenuProjects from "./navbar-components/NavDropdownMenuProjects";
import NavButton from "./navbar-components/NavButton";
import SideNav from "./navbar-components/SideNav";

/* Function Imports */
// import useUser from "../../lib/useUser";

/* Data Imports */
import AppConfig from "../../AppConfig";

const useStyles = makeStyles((theme) => ({
  navbar: {
    zIndex: 3,
    backgroundColor: "#FFFFFF",
    color: "#000000",
    height: "4rem",
    padding: "0rem 1rem 0rem 1rem",
  },
  menuButton: {
    marginTop: "0.5rem",
    color: "#808080",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: {
    height: "4rem",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      width: "100%",
    },
    display: "flex",
    justifyContent: "space-between",
  },
  hnav: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  link: {
    marginTop: "0.75rem",
    "&:hover": {
      textDecoration: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileNavSec: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mobilenav: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "row",
    marginTop: "0.3rem",
    "&:hover": {
      textDecoration: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const DispenseIcon = (iconString) => {
  switch (iconString) {
    case "material/navigation/menu":
      return "/images/icons/misc/menu.svg";
    case "navbar/logo":
      return "/images/logo/logo.svg";
    case "navbar/mobilelogo":
      return "/images/icons/logo/favicon.svg";
    default:
      return "/images/icons/misc/menu.svg";
  }
};

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};
const Navbar = (props) => {
  const classes = useStyles();
  // const { user, mutateUser } = useUser();
  const [drawerSwitch, setDrawerSwitch] = React.useState(false);

  return (
    <React.Fragment>
      {/* SideNav Phone */}

      <SideNav
        open={drawerSwitch}
        onClose={() => {
          setDrawerSwitch(false);
        }}
        user={props.user}
      />

      {/* Appbar */}

      <ElevationScroll {...props}>
        <AppBar className={classes.navbar} position="fixed">
          <Toolbar className={classes.toolbar}>
            {/* Phone Navbar */}

            <div className={classes.mobileNavSec}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  setDrawerSwitch(!drawerSwitch);
                }}
              >
                <img
                  alt="Menu"
                  loading="lazy"
                  referrerPolicy="no-referrer"
                  height={28}
                  width={28}
                  style={{ marginRight: "0.25rem" }}
                  src={DispenseIcon("material/navigation/menu")}
                />
              </IconButton>

              <Link
                href="/"
                rel="noopener"
                aria-label="Sentient"
                title="Home"
                className={classes.mobilenav}
              >
                <img
                  alt="Sentient"
                  loading="lazy"
                  referrerPolicy="no-referrer"
                  height={50}
                  width={50}
                  src={DispenseIcon("navbar/mobilelogo")}
                />
              </Link>
            </div>

            {/* Desktop Navbar */}

            <Link
              href="/"
              rel="noopener"
              aria-label="Sentient"
              title="Home"
              className={classes.link}
            >
              <img
                alt="Sentient"
                loading="lazy"
                referrerPolicy="no-referrer"
                height={40}
                width={140}
                src={DispenseIcon("navbar/logo")}
              />
            </Link>

            <div className={classes.hnav}>
              <NavButton href="/" text="Home" />
              <NavDropdownMenuProjects text={"Projects"} />
              <NavButton href="/construction" text="Construction" />
              <NavButton href="/about-us" text="About us" />
              <NavButton href="#contactus" text="Contact us" />
              {!props.user?.isLoggedIn && (
                <NavButton href="/sign-in" text="Sign in" />
              )}
              {props.user?.isLoggedIn && (
                <NavDropdownMenuUser
                  href={
                    props.user?.role == "client"
                      ? "/user-dashboard"
                      : "/staff-dashboard"
                  }
                  text={props.user?.name ? props.user?.name : "Dashboard"}
                  onClickSignOut={async (e) => {
                    e.preventDefault();
                    // mutateUser(
                    await fetch(
                      encodeURI(
                        AppConfig.apiUrlPrefix + "authentication/user-sign-out"
                      ),
                      {
                        method: "POST",
                        cache: "no-store",
                      }
                    ),
                      false;
                    // );
                    window.location = "/sign-in";
                  }}
                />
              )}
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar className={classes.toolbar} />
    </React.Fragment>
  );
};
export default withRouter(Navbar);
